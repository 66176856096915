<script setup>
import { onMounted, ref} from 'vue'
import axios from 'axios';
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
const message = ref('');

const changePassword = ref(false)

const credentials = ref({
    username:'',
    password:'',
    new_password:'',
    conf_new_password:''
})

const resetForm = ()=>{
    credentials.value.username='';
    credentials.value.password='';
    credentials.value.new_password='';
    credentials.value.conf_new_password='';
    changePassword.value = false;
    message.value = '';
}

const login = async ()=>{
    axios.post('/login',credentials.value)
        .then((res)=>{
            window.location.href = "/administration";
        })
        .catch((err)=>{
            if(err.response){
                message.value = err.response.data.message;
                console.log('err.response.data',err)
                console.log('err.response.data',err.response)
                console.log('err.response.status',err.response.status)

                if(err.response.status == 480){
                    changePassword.value = true;
                }
            }
        })       
}

const doChangePassword = async ()=>{
    
    axios.post('/updatePassword',credentials.value)
        .then((res)=>{
            window.location.href = "/administration";
        })
        .catch((err)=>{
            if(err.response){
                message.value = err.response.data.message;
                console.log('err.response.data',err.response.status)
                console.log('err.response.data',err.response)
                console.log('err.response.status',err.response.status)

                if(err.response.status == 480){
                    changePassword.value = true;
                }
            }
        }) 
}
</script>

<template lang="pug">
div.uk-section.uk-section-muted.uk-flex.uk-flex-middle.uk-animation-fade(uk-height-viewport)
    div.uk-width-1-1
        div.uk-container
            div.uk-grid-margin.uk-grid.uk-grid-stack(uk-grid)
                div(class="uk-width-1-1@m")
                    div.uk-margin.uk-width-large.uk-margin-auto.uk-card.uk-card-default.uk-card-body.uk-box-shadow-large.uk-text-center
                        img.uk-margin-bottom(src="/public/logo.png" width="200" height="" alt="" uk-img)
                        div.uk-alert-danger(uk-alert v-if="message")
                            a.uk-alert-close(uk-close)
                            p {{ message }}
                        template(v-if="!changePassword")
                            Form(@submit="login")                            
                                
                            
                                div.uk-margin(v-if="!changePassword")
                                    div.uk-inline.uk-width-1-1
                                        span.uk-form-icon(uk-icon="icon: user")
                                        input.uk-input.uk-form-large(name="username" type="text" placeholder="Nom d'utilisateur" v-model="credentials.username")
                                
                                div.uk-margin
                                    div.uk-inline.uk-width-1-1
                                        span.uk-form-icon(uk-icon="icon: lock")
                                        input.uk-input.uk-form-large(name="password" type="password" placeholder="Mot de passe" v-model="credentials.password")

                                div.uk-margin(v-if="!changePassword")
                                    button.uk-button.uk-button-primary.uk-button-large.uk-width-1-1(type="submit") Ouvrir une session
                                div.uk-text-left
                                    a.uk-button.uk-button-link(href="/")
                                        span(uk-icon="arrow-left")
                                        | Retour à la page principale
                                
                        template(v-else)
                            Form(@submit="doChangePassword")
                                div.uk-margin
                                    div.uk-inline.uk-width-1-1
                                        span.uk-form-icon(uk-icon="icon: lock")
                                        input.uk-input.uk-form-large(name="password" type="password" placeholder="Mot de Passe" v-model="credentials.password")

                                hr
                                div.uk-margin
                                    div.uk-inline.uk-width-1-1
                                        span.uk-form-icon(uk-icon="icon: lock")
                                        input.uk-input.uk-form-large(name="password" type="password" placeholder="Nouveau Mot de Passe" v-model="credentials.new_password")
                                
                                div.uk-margin
                                    div.uk-inline.uk-width-1-1
                                        span.uk-form-icon(uk-icon="icon: lock")
                                        input.uk-input.uk-form-large(name="conf_password" type="password" placeholder="Confirmer le Mot de Passe" v-model="credentials.conf_new_password")
                                
                                div.uk-margin
                                    button.uk-button.uk-button-primary.uk-button-large.uk-width-1-1(type="submit") Changer le mot de passe
                                div.uk-margin
                                    button.uk-button.uk-button-link.uk-button-large.uk-width-1-1(type="button" @click="resetForm()") Annuler                             
                            
                            
                        //- div.uk-text-small.uk-text-center
                        //-     | Not registered? 
                        //-     a(href="register") Create an account
</template>
